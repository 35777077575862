
import { defineComponent } from "vue";
import AppPage from "@/components/AppPage.vue";
import moment from "moment-timezone";
import Editor from "./Editor.vue";
import AttentionPopup from "@/components/AttentionPopup.vue";
import { mapState } from "vuex";

import axios from "axios";

interface User {
  photo: string;
  name: string;
  email: string;
  telegram: string;
  timezoneoffset: string;
  country: string;
  role: string;
}

export default defineComponent({
  components: {
    AppPage,
    Editor,
    AttentionPopup,
  },
  data() {
    return {
      search: "",
      roles: [],
      profileDate: "",
      defaultImg: require("@/assets/default-user-image.png"),
      userToEdit: {} as User | null,
      isAttentionPopupShowed: false,
      isEditorShowed: false,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  created() {
    this.load();
  },
  methods: {
    closePopup() {
      this.isAttentionPopupShowed = true;
    },
    applyClosePopup() {
      this.isAttentionPopupShowed = false;
      this.userToEdit = null;
    },
    getStarCount(role: string) {
      let count = 0;

      switch (role) {
        case "AdminContent":
          count = 1;
          break;
        case "AdminGeneral":
          count = 2;
          break;
        case "AdminPlus":
          count = 3;
          break;
        case "SuperAdmin":
          count = 4;
          break;
        default:
          count = 5;
      }

      return count;
    },
    getCurrentDate() {
      const offset = this.user?.timezoneoffset || "+03:00";
      const dayNaming = [
        "Воскресенье",
        "Понедельник",
        "Вторник",
        "Среда",
        "Четверг",
        "Пятница",
        "Суббота",
      ];
      const monthNaming = [
        "января",
        "ферваля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
      ];
      const date = new Date();
      const day = date.getDay();
      const month = date.getMonth();
      const year = date.getFullYear();
      this.profileDate =
        dayNaming[day] +
        ", " +
        date.getDate() +
        " " +
        monthNaming[month] +
        " " +
        year +
        ", " +
        moment().utcOffset(offset).format("HH:mm");
    },
    async load() {
      this.isEditorShowed = false;
      try {
        const { data } = await axios.get("/api/admin-roles");
        this.roles = data;
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
    },
    openEditor() {
      this.userToEdit = this.user;
      this.isEditorShowed = true;
    },
  },
  mounted() {
    this.getCurrentDate();
    setInterval(() => {
      this.getCurrentDate() as any;
    }, 1000);
  },
});
