
import { defineComponent } from "vue";
import Popup from "@/components/Popup.vue";
import TextInput from "@/components/TextInput.vue";
import Dropdown from "@/components/Dropdown.vue";
import Button from "@/components/Button.vue";
import * as ct from "countries-and-timezones";

import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import axios from "axios";

interface Country {
  name: string;
}

interface FullCountryInfo {
  id: string;
  name: string;
  timezones: string[];
}

export default defineComponent({
  components: {
    Popup,
    TextInput,
    Dropdown,
    Button,
    VueCropper,
  },
  props: {
    user: Object,
  },
  emits: ["close", "saved", "update:user"],
  data() {
    return {
      loading: false,
      errors: {} as { [key: string]: string | null },
      countries: [] as any,
      countriesInfo: [] as any,
      filteredCountries: [] as Array<Country>,
      isCropperExist: false,
      image: {},
      photo: "",
    };
  },
  created() {
    this.countriesInfo = Object.values(ct.getAllCountries()).map((el: any) => ({
      id: el.id,
      name: el.name,
    }));
    this.filteredCountries = this.countriesInfo;
  },
  computed: {
    effectiveCountry() {
      return this.countriesInfo.find(
        (country: any) => country.name === this.user?.country
      )?.id;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getCountriesSearchable(value: string) {
      if (value) {
        this.filteredCountries = this.countriesInfo.filter((item: Country) =>
          item.name.toLowerCase().includes(value.toLowerCase())
        );
      } else {
        this.filteredCountries = this.countriesInfo;
      }
    },
    async imageToBlob(image: any) {
      return image && (await (await fetch(image)).blob());
    },
    async setImage(e: any) {
      const file = e.target.files[0];
      if (!file.type.includes("image/")) {
        alert("Please select an image file");
        return;
      }
      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.image = event.target?.result || {};
          this.isCropperExist = true;
          this.$emit("update:user", {
            ...this.user,
            photo: this.imageToBlob(this.image),
          });
          // rebuild cropperjs with the updated source
          // this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    async cropImage() {
      const cropper = this.$refs.cropper as any;
      const data = cropper.getData({ round: true });

      this.photo = cropper.getCroppedCanvas(data).toDataURL();
      this.isCropperExist = false;
    },
    emitPatch(patch: any) {
      if (patch.country) {
        const selectedCountry = this.countriesInfo.find(
          (country: any) => country.id === patch.country
        )?.name;
        const country = Object.values(ct.getAllCountries()).find(
          (el: any) => el.name === selectedCountry
        );
        const id = (country as FullCountryInfo).id;
        const timezones = ct.getTimezonesForCountry(id.toString());
        this.$emit("update:user", {
          ...this.user,
          ...patch,
          country: selectedCountry,
          timezoneoffset: timezones![0]?.dstOffsetStr,
        });
      } else {
        this.$emit("update:user", {
          ...this.user,
          ...patch,
          country: patch.country !== null ? this.user?.country : null,
          timezoneoffset:
            patch.country !== null ? this.user?.timezoneoffset : null,
        });
      }

      for (const k of Object.keys(patch)) {
        this.errors[k] = null;
      }
    },
    async saveUser() {
      this.loading = true;
      let formData = new FormData();

      for (const name in this.user) {
        formData.append(name, this.user[name]);
      }

      formData.append("photo", await this.imageToBlob(this.photo));

      try {
        await axios.post("/api/profile/update", formData);
        this.$store.commit("setUser", {
          ...this.user,
          photo: this.photo || this.user?.photo,
        });
        this.$emit("saved");
      } catch (e) {
        const status = e.response && e.response.status;
        if (status === 400) {
          this.errors = e.response.data;
        } else {
          this.$router.push(`/error/${status || 500}`);
        }
      }

      this.loading = false;
    },
  },
});
